<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <iframe ref="iframe" :src="importUrl" class="ui-height-100" frameborder="0"></iframe>
    </div>
</template>
<script>
export default {
    name: 'HtmlPanel',
    data() {
        return {
            importUrl: '',
            loading: false,
        };
    },
    watch: {
        $route: {
            handler(val) {
                this.load();
            }
        }
    },
    methods: {
        load() {
            if (
                this.$route.name === 'External-html-panel' && this.$route.query && this.$route.query.url
            ) {
                if (this.importUrl === this.$route.query.url) {return;}

                if (this.$refs.iframe) {
                    this.$refs.iframe.onload = () => {
                        this.loading = false;
                        const params = {
                            token: this.$store.state.jwtToken
                        };
                        this.$refs.iframe.contentWindow.postMessage(params, '*');
                    };
                }
                this.loading = true;
                this.importUrl = this.$route.query.url;
            }
        },
    },
    mounted() {
        this.load();
    },

};
</script>
